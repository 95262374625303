import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  List,
  ListItem,
  Text,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { uniqueId } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { validateOrder } from '@/api/api';
import ApiError from '@/api/ApiError';
import { Shift, ValidOrder } from '@/api/types';
import CartItemCard from '@/components/CartItemCard';
import CtaButton from '@/components/CtaButton';
import PickupForm from '@/components/PickupForm';
import { useCart, useCartRequest } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import { ItemCart, PickupTypesEnum, ResponsivePropertyDrawer } from '@/types';

import CartDrawerFooter from './CartDrawerFooter';

interface Props {
  cartItems: ItemCart[];
  currencyCode: string;
  isDrawerOpen: boolean;
  isRestaurantOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  shifts: Shift[];
}

function CartDrawer({ isDrawerOpen, isRestaurantOpen, shifts, cartItems, onClose, onOpen, currencyCode }: Props) {
  const navigate = useNavigate();
  const submitButton = useRef(null);

  const { cart, setCart, resetCart } = useCart();
  const cartRequest = useCartRequest();

  const [pickupType, setPickupType] = useState<PickupTypesEnum>(PickupTypesEnum.ASAP);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useBoolean(false);

  const {
    data: price,
    isError,
    isLoading,
  } = useQuery<ValidOrder, ApiError, number>(['validateOrder', cartRequest], () => validateOrder(cartRequest), {
    enabled: cartRequest.items.length !== 0 && (isRestaurantOpen || !!cart.pickupTime),
    select: (validOrder) => {
      return validOrder.totalPrice;
    },
  });

  const responsiveDrawerPlacement = useBreakpointValue<'bottom' | 'right'>({ base: 'bottom', md: 'right' });

  const responsiveDrawerSize = useBreakpointValue<'xl' | 'md'>({ base: 'xl', md: 'md' });

  const responsiveDrawerProperties = useBreakpointValue<ResponsivePropertyDrawer>({
    base: { height: '90vh', borderTopRadius: '3xl' },
    md: { borderLeftRadius: 'none' },
    lg: { borderLeftRadius: 'none' },
  });

  const handlePickupTimeChange = (pickupTime: Dayjs | null) => {
    setCart({ ...cart, pickupTime: pickupTime?.local().format() });
  };

  const handlePickupTypeChange = useCallback(
    (pickupType: PickupTypesEnum) => {
      setPickupType(pickupType);
    },
    [setPickupType]
  );

  useEffect(() => {
    if (pickupType === PickupTypesEnum.LATER && !cart.pickupTime) {
      setIsCheckoutDisabled.on();
    } else {
      setIsCheckoutDisabled.off();
    }
  }, [pickupType, cart, setIsCheckoutDisabled]);

  const handleRemoveItemCart = (cartPosition: number) => {
    const items = [...cart.items.filter((_, index) => index !== cartPosition)];

    dataLayer.logItemRemoved(cart.kitchenLabel, cart.conceptLabel, cart.items[cartPosition], 'online');
    setCart({ ...cart, items });

    if (items.length === 0) onClose();
  };

  const numberOfItems = useMemo(() => cart.items.reduce((quantityAcc, item) => quantityAcc + item.quantity, 0), [cart]);

  const handleSubmit = () => {
    navigate('/checkout');
  };

  const clearCart = () => {
    resetCart();
    onClose();
  };

  return (
    <>
      {cart.items.length > 0 && (
        <CtaButton onClick={onOpen}>
          <Trans>My cart</Trans>
          {` (${numberOfItems})`}
        </CtaButton>
      )}
      <Drawer
        initialFocusRef={submitButton}
        isOpen={isDrawerOpen}
        placement={responsiveDrawerPlacement}
        size={responsiveDrawerSize}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent {...responsiveDrawerProperties}>
          <DrawerCloseButton top={4} />
          <DrawerHeader borderBottomWidth="2px" textAlign="center">
            <Trans>My cart</Trans>
          </DrawerHeader>
          <DrawerBody>
            <PickupForm
              isOpen={isRestaurantOpen}
              shifts={shifts}
              onTypeChange={handlePickupTypeChange}
              onTimeChange={handlePickupTimeChange}
            />
            <hr style={{ margin: '1rem -24px' }} />
            <Text fontWeight="500" fontSize="md">
              <Trans>Your items</Trans>
            </Text>
            <List>
              {cartItems.map((item, index) => (
                <ListItem
                  borderBottomWidth={cartItems.length - 1 !== index ? '1px' : undefined}
                  key={uniqueId(item.menuElementUuid)}
                >
                  <CartItemCard
                    handleRemoveItemCart={() => handleRemoveItemCart(index)}
                    itemCart={item}
                    currencyCode={currencyCode}
                  />
                </ListItem>
              ))}
            </List>
          </DrawerBody>
          <CartDrawerFooter
            buttonLabel={t`Go to checkout`}
            currencyCode={currencyCode}
            isError={isError}
            isLoading={isLoading}
            isSubmitDisabled={isCheckoutDisabled}
            onClose={onClose}
            onErrorClick={clearCart}
            onSubmit={handleSubmit}
            price={price}
            ref={submitButton}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CartDrawer;
