import { groupBy } from 'lodash-es';

import { ConceptInformation } from '@/api/gateway-click-collect/concepts';
import { Restaurant } from '@/api/gateway-click-collect/restaurants';
import { RestaurantWithConcept } from '@/types';

export const buildKitchenInfos = ({
  restaurantList,
  concepts,
}: {
  restaurantList?: Restaurant[];
  concepts?: ConceptInformation[];
}): RestaurantWithConcept[][] => {
  if (!concepts?.length || !restaurantList?.length) return [];

  const restaurantListWithConcepts = restaurantList
    .map(({ conceptUuid, ...restaurant }) => ({
      ...restaurant,
      concept: concepts.find(({ uuid }) => uuid === conceptUuid),
    }))
    .filter(
      (restaurantWithConcept): restaurantWithConcept is RestaurantWithConcept =>
        !!restaurantWithConcept.concept && !!restaurantWithConcept.distance
    );
  const locationRestaurantsList = groupBy(restaurantListWithConcepts, 'locationUuid');

  return Object.values(locationRestaurantsList).sort((restaurantListA, restaurantListB) =>
    restaurantListA[0].distance! > restaurantListB[0].distance! ? 1 : -1
  );
};
