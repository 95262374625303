import { Card, CardBody, Flex } from '@chakra-ui/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';

import { Coords } from '@/api/types';
import { RestaurantCard } from '@/components/RestaurantCard';
import { RestaurantWithConcept } from '@/types';

import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';

type KitchenGeolocationCardProps = {
  coords: Coords;
  restaurantList: RestaurantWithConcept[];
};

export const KitchenGeolocationCard = ({ coords, restaurantList }: KitchenGeolocationCardProps) => {
  const [wrapper] = useAutoAnimate();

  const { address, distance, kitchenLabel } = restaurantList[0];

  const sortedRestaurantInfos = sortBy(restaurantList, 'label');

  const kitchenConcepts = restaurantList.map(({ concept }) => concept);

  const googleMapUrl = useMemo(() => {
    const { latitude, longitude } = coords;
    return encodeURI(`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${address}`);
  }, [address, coords]);

  return (
    <Card boxShadow="unset" borderRadius={8}>
      <CardHeader address={address} distance={distance} addressUrl={googleMapUrl} kitchenLabel={kitchenLabel} />
      <CardBody paddingBlock={0}>
        <Flex ref={wrapper} direction="row" flexWrap="nowrap" overflow="auto" gap={4}>
          {sortedRestaurantInfos.map((restaurant) => (
            <RestaurantCard
              compact
              flexBasis={300}
              flexGrow={1}
              flexShrink={0}
              key={restaurant.restaurantPlatformId}
              restaurant={restaurant}
            />
          ))}
        </Flex>
      </CardBody>
      <CardFooter concepts={kitchenConcepts} />
    </Card>
  );
};
