import camelize from 'camelize-ts';
import { locationType } from 'react-static-google-map';
import snakeize from 'snakeize';

import ApiError from '@/api/ApiError';
import {
  CartRequest,
  GetTerminalStatusMutation,
  Menu,
  Order,
  PrepareOrderOnSite,
  PrepareOrderOnSiteRequest,
  PrepareOrderResponse,
  SendCustomerEmailRequest,
  TerminalAuthInfo,
  TerminalStatus,
  ValidOrder,
} from '@/api/types';

export const getRestaurantAssets = async (restaurantPlatformId: string): Promise<Record<string, string>> => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/menu/${restaurantPlatformId}/assets`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return jsonResponse;
};

export const pairTerminal = async (
  {
    token,
    locationUuid,
    terminalPairingCode,
    previousTerminalUuid,
  }: {
    token: string;
    locationUuid: string;
    terminalPairingCode: string;
    previousTerminalUuid?: string | undefined;
  },
  signal?: AbortSignal
) => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/terminal`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify(
      snakeize({
        locationUuid,
        terminalPairingCode,
        previousTerminalUuid,
      })
    ),
    signal,
  });

  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return camelize(jsonResponse) as TerminalAuthInfo;
};

export const fetchTerminalStatus = async ({
  locationUuid,
  terminalUuid,
  authenticationPassPhrase,
}: GetTerminalStatusMutation & {
  authenticationPassPhrase: string;
}) => {
  const response = await fetch(
    `${import.meta.env.VITE_CUSTOMER_ORDERS_API}/terminal/${terminalUuid}?location_uuid=${locationUuid}`,
    {
      method: 'GET',
      headers: { Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`, 'Content-Type': 'application/json' },
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return camelize(jsonResponse) as TerminalStatus;
};

export const deleteOrderPayment = async ({
  authenticationPassPhrase,
  locationUuid,
  terminalUuid,
}: GetTerminalStatusMutation & {
  authenticationPassPhrase: string;
}) => {
  const response = await fetch(
    `${import.meta.env.VITE_CUSTOMER_ORDERS_API}/terminal/${terminalUuid}/action?location_uuid=${locationUuid}`,
    {
      method: 'DELETE',
      headers: { Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`, 'Content-Type': 'application/json' },
    }
  );

  if (!response.ok) throw new Error(`Payment cancelation failed for terminal ${terminalUuid} in ${locationUuid}`);

  return { terminalUuid, locationUuid };
};

export const getMenu = async (menuUuid: string): Promise<Menu> => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/menu/${menuUuid}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return camelize(jsonResponse) as Menu;
};

export const prepareOrder = async (
  cartRequest: CartRequest,
  currentOrderUuid: string | null = null
): Promise<PrepareOrderResponse> => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/orders/prepare/web`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...cartRequest, order_uuid: currentOrderUuid }),
  });
  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return jsonResponse as PrepareOrderResponse;
};

export const prepareOrderOnSite = async ({
  prepareOrderOnSiteParam,
  authenticationPassPhrase,
}: {
  prepareOrderOnSiteParam: PrepareOrderOnSiteRequest;
  authenticationPassPhrase: string;
}): Promise<PrepareOrderOnSite> => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/orders/prepare/onsite`, {
    method: 'POST',
    headers: { Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`, 'Content-Type': 'application/json' },
    body: JSON.stringify(snakeize(prepareOrderOnSiteParam)),
  });

  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return jsonResponse;
};

export const validateOrder = async (cartRequest: CartRequest): Promise<ValidOrder> => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/orders/validate`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(cartRequest),
  });
  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return camelize(jsonResponse) as ValidOrder;
};

export const getOrder = async (orderUuid: string): Promise<Order> => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/orders/${orderUuid}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const jsonResponse = await response.json();

  if (!response.ok) throw new ApiError(jsonResponse);

  return jsonResponse as Order;
};

export const sendCustomerEmail = async ({
  paymentUuid,
  customerEmail,
  orderUuid,
  marketingConsent = false,
}: SendCustomerEmailRequest) => {
  const response = await fetch(`${import.meta.env.VITE_CUSTOMER_ORDERS_API}/orders/${orderUuid}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      snakeize({
        paymentUuid,
        customerEmail,
        marketingConsent,
      })
    ),
  });

  if (!response.ok) throw new ApiError(await response.json());
};

export const getCoordinates = async (address: string): Promise<locationType> => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${
      import.meta.env.VITE_MAPS_GEOCODING_API_KEY
    }`
  );
  const jsonResponse = await response.json();

  return jsonResponse.results[0].geometry.location;
};
