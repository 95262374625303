import { Spinner } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useConceptsConfigurationQuery, useLocationRestaurantsQuery } from '@/api/gateway-click-collect';
import { useDeliveryPlatformsQuery } from '@/api/gateway-click-collect/locations';
import CtaButton from '@/components/CtaButton';
import PageLoader from '@/components/PageLoader';
import WebviewMessage from '@/components/WebviewMessage';
import { MINUTE_MS } from '@/constants';
import { useOrder } from '@/contexts/order';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { useUpdateMetadata } from '@/hooks/useUpdateMetadata';
import AppLayout from '@/layouts/AppLayout';

import { hasOrderExpired } from './HomePage.helpers';
import { RestaurantsList } from './RestaurantsList';

const FIVE_MINUTES_REFETCH_INTERVAL = MINUTE_MS * 5;

export const HomePage = () => {
  const navigate = useNavigate();
  const { locationUuid } = useParams() as { locationUuid: string };

  const [searchParams] = useSearchParams();

  const isDelivery = !!searchParams.get('delivery');

  const {
    data: restaurantsList = [],
    isLoading,
    error: areRestaurantsOnError,
  } = useLocationRestaurantsQuery({
    requestParams: {
      locationUuid,
      withOpeningInformation: true,
    },
    options: {
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
    },
  });

  const { data: deliveryPlatform, isLoading: isDeliveryPlatformLoading } = useDeliveryPlatformsQuery({
    requestParams: {
      locationUuid,
    },
    options: {
      enabled: !!locationUuid,
    },
  });

  const {
    data: conceptsInformations = [],
    isLoading: areConceptsLoading,
    isError: areConceptsOnError,
  } = useConceptsConfigurationQuery({
    options: {
      cacheTime: Infinity,
      staleTime: Infinity,
      select: ({ concepts }) => concepts,
    },
  });

  const kitchenLabel = restaurantsList?.[0]?.kitchenLabel ?? t`Unknown`;

  useUpdateMetadata({ title: kitchenLabel });

  const { order, setOrder } = useOrder();

  if (hasOrderExpired(order)) setOrder(null);

  usePageViewTracker(kitchenLabel);

  if (areRestaurantsOnError || areConceptsOnError) return <Navigate to="notFound" />;

  if ((isDeliveryPlatformLoading && isDelivery) || isLoading || areConceptsLoading) return <PageLoader />;

  const isOrderInProgress = !!order && order.status !== 'delivered';

  return (
    <AppLayout
      title={t`Our restaurants`}
      hasPoweredBy
      maxWidthBreakpoints={{ lg: '900px' }}
      headerExtraContent={<WebviewMessage />}
      hasArrowBack
      url={'/'}
    >
      <RestaurantsList
        conceptsInformations={conceptsInformations}
        restaurants={restaurantsList}
        deliveryPlatformsMap={deliveryPlatform?.deliveryRestaurants}
        isDelivery={isDelivery}
        locationUuid={locationUuid}
      />
      {isOrderInProgress && (
        <CtaButton onClick={() => navigate(`/orderStatus/${order.uuid}`)}>
          <Spinner mr={2} />
          <Trans>Order in progress</Trans>
        </CtaButton>
      )}
    </AppLayout>
  );
};
