import { Avatar, AvatarGroup, CardFooter as ChakraCardFooter, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { ConceptInformation } from '@/api/gateway-click-collect/concepts';
import { widthSortClassificationPictures } from '@/helpers/menu.helpers';

type CardFooterProps = {
  concepts: ConceptInformation[];
};

export const CardFooter = ({ concepts }: CardFooterProps) => {
  return (
    <ChakraCardFooter flexDirection="column" gap={1}>
      <Text fontSize="xs" color="gray.500" fontWeight={300}>
        <Trans>In this location</Trans>
      </Text>
      <AvatarGroup spacing={-4}>
        {concepts.map(({ logoPictures, conceptName, uuid }) => {
          const sortedLogoPictures = widthSortClassificationPictures(logoPictures);

          return (
            <Avatar
              key={uuid}
              name={conceptName}
              src={sortedLogoPictures[0].url}
              showBorder
              bg="white"
              borderColor="gray.100"
              borderWidth={1}
            />
          );
        })}
      </AvatarGroup>
    </ChakraCardFooter>
  );
};
