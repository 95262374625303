import { ConceptInformation, Restaurant } from '@tasterkitchens/client-ts-gateway-click-collect-v1';

import { MenuElement, Modifier, ModifierGroup, TerminalStatusEnum } from '@/api/types';

export interface ModifierCart extends Modifier {
  quantity: number;
  selectedModifierGroups?: ModifierGroupCart[];
}

export interface ModifierGroupCart extends ModifierGroup {
  selectedModifiers?: ModifierCart[];
}

export interface ItemCart extends MenuElement {
  quantity: number;
  selectedModifierGroups?: ModifierGroupCart[];
  price?: number;
}

export interface Cart {
  restaurantPlatformId: string;
  items: ItemCart[];
  pickupTime?: string | null;
  restaurantAddress: string;
  restaurantName: string;
  accessInstructions: string;
  kitchenLabel: string;
  conceptLabel: string;
}

export interface ResponsivePropertyDrawer {
  height?: string;
  size?: string;
  top?: string;
  borderTopRadius?: string;
  borderLeftRadius?: string;
  borderTopRightRadius?: string;
  borderBottomLeftRadius?: string;
}

export interface EmailRequestInfo {
  isValid: boolean;
  label: string | null;
  logo: string | undefined;
  color?: string;
}

export enum PickupTypesEnum {
  ASAP = 'ASAP',
  LATER = 'LATER',
}

export const OrderStatus = {
  ACCEPTED: 'ACCEPTED',
  READY: 'READY',
  DELIVERED: 'DELIVERED',
  REJECTED: 'REJECTED',
} as const;

export type OrderStatus = keyof typeof OrderStatus;

export const Review = {
  GOOD: 'GOOD',
  BAD: 'BAD',
} as const;

export type Review = keyof typeof Review;

export type OrderChannel = 'online' | 'onsite';

export const TerminalStatusWithIssues = [
  TerminalStatusEnum.BUSY,
  TerminalStatusEnum.OFFLINE,
  TerminalStatusEnum.UNAVAILABLE,
] as const;

export type TerminalIssue = (typeof TerminalStatusWithIssues)[number];

export interface RestaurantWithConcept extends Omit<Restaurant, 'conceptUuid'> {
  concept: ConceptInformation;
}
